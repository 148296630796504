/**
 * Custom error class for API communication with standardized error handling
 * @class APIError
 * @extends {Error}
 */
export class APIError extends Error {
  /**
   * Creates an instance of APIError
   * @param {number} status - HTTP status code
   * @param {string} message - Error message
   * @param {string} [code] - Error code identifier
   * @param {Record<string, unknown>} [details] - Additional error details
   */
  constructor(
    public readonly status: number,
    message: string,
    public readonly code?: string,
    public readonly details?: Record<string, unknown>
  ) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }

  /**
   * Serializes error for client responses
   * @returns {Object} Serialized error object
   */
  toJSON() {
    return {
      status: this.status,
      message: this.message,
      code: this.code,
      details: this.details,
      stack: process.env.NODE_ENV === "development" ? this.stack : undefined,
    };
  }

  /** Creates a 400 Bad Request error
   * @param {string} message - Error message
   * @returns {APIError} Bad request error instance
   */
  static readonly BAD_REQUEST = (message: string) => new APIError(400, message, "BAD_REQUEST");

  /** Creates a 401 Unauthorized error
   * @param {string} [message="Authentication required"] - Error message
   * @returns {APIError} Unauthorized error instance
   */
  static readonly UNAUTHORIZED = (message = "Authentication required") =>
    new APIError(401, message, "UNAUTHORIZED");

  /** Creates a 403 Forbidden error
   * @param {string} [message="Insufficient permissions"] - Error message
   * @returns {APIError} Forbidden error instance
   */
  static readonly FORBIDDEN = (message = "Insufficient permissions") =>
    new APIError(403, message, "FORBIDDEN");

  /** Creates a 404 Not Found error
   * @param {string} [message="Resource not found"] - Error message
   * @returns {APIError} Not found error instance
   */
  static readonly NOT_FOUND = (message = "Resource not found") =>
    new APIError(404, message, "NOT_FOUND");

  /** Creates a 409 Conflict error
   * @param {string} [message="Resource conflict"] - Error message
   * @returns {APIError} Conflict error instance
   */
  static readonly CONFLICT = (message = "Resource conflict") =>
    new APIError(409, message, "CONFLICT");

  /** Creates a 429 Rate Limited error
   * @param {number} [retryAfter] - Seconds until retry is allowed
   * @returns {APIError} Rate limited error instance
   */
  static readonly RATE_LIMITED = (retryAfter?: number) =>
    new APIError(429, "Too many requests", "RATE_LIMITED", { retryAfter });

  /** Creates a 500 Server Error
   * @param {string} [message="Internal server error"] - Error message
   * @returns {APIError} Server error instance
   */
  static readonly SERVER_ERROR = (message = "Internal server error") =>
    new APIError(500, message, "INTERNAL_ERROR");
}

/**
 * Converts any error to an APIError instance
 * @param {unknown} error - Error to be handled
 * @returns {APIError} Standardized API error
 */
export const handleAPIError = (error: unknown): APIError => {
  if (error instanceof APIError) return error;
  if (error instanceof Error) return new APIError(500, error.message);
  return new APIError(500, "Unknown error occurred");
};

/**
 * Handles API responses and throws standardized errors
 * @param response - The fetch Response object
 * @returns Promise resolving to the response data
 * @throws {APIError} If the response indicates an error
 */
export async function handleApiResponse<TResponse>(response: Response): Promise<TResponse> {
  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new APIError(
      response.status,
      errorData.error || `API request failed with status ${response.status}`,
      errorData.code
    );
  }
  return response.json();
}

/**
 * Standardizes error messages from various error sources
 * @param error - The error to format
 * @returns Formatted error message string
 */
export function formatErrorMessage(error: unknown): string {
  if (error instanceof APIError) return error.message;
  if (error instanceof Error) return error.message;
  if (typeof error === "string") return error;
  return "Unknown error occurred";
}

/**
 * Logs API errors with optional error reporting integration
 * @param error - The error to log
 * @param context - Context information for the error
 * @param errorReporter - Optional error reporting function (e.g. Sentry)
 */
export function logApiError(
  error: unknown,
  context: string,
  errorReporter?: (error: Error, context: { tags: { context: string } }) => void
): void {
  console.error(`API Error (${context}):`, error);
  if (error instanceof Error && errorReporter) {
    errorReporter(error, { tags: { context } });
  }
}

// Common response type for API errors
export type ApiErrorResponse = {
  code: string;
  message: string;
  details?: Record<string, unknown>;
};

/**
 * Determines if an error should trigger a retry attempt
 * @param error - The error to check
 * @returns true if the error is retryable
 */
export function isRetryableError(error: unknown): boolean {
  if (!(error instanceof APIError)) return false;
  return [429, 502, 503, 504].includes(error.status);
}
