import { AxiosResponse } from "axios";
import { DateTime } from "luxon";

import { logger as baseLogger } from "@core/logger";
import { urlWithParams } from "@core/utils/urlWithParams";
import URLS from "@config/urls";

import { AxiosApiClient } from "../base/apiClient";
import { Network, Substation } from "@core/models";
import { ApiResponse, CollectionResponse } from "./api.types";
import { Auth0Client } from "@auth0/auth0-spa-js";

const logger = baseLogger.getSubLogger({ name: "mdsl.api" });

export type ValidMeterComponent = "heat_energy" | "volume" | "supplytemp" | "returntemp";

export type ValidMeterStage = "clean" | "raw" | "processed";

export type ValidMeter = "primary" | "secondary";


export type BaseMeterDataParams = {
  network_uid: string;
  stage?: ValidMeterStage;
  meter?: ValidMeter;
  components: ValidMeterComponent[];
  flags?: string[];
  range?: Record<string, string>;
  ts_start: DateTime<boolean>;
  ts_end: DateTime<boolean>;
  page_size?: number;
}

export type GetSingleSubstationMeterDataParams = BaseMeterDataParams & {
  substation_uid: string;
}

export type GetSubstationsMeterDataParams = BaseMeterDataParams & {
  resource_uids?: string[];
}

interface PagedResponse<T> {
  data: {
    collection: T[];
    has_next_page: boolean;
  };
  error: null | { message: string };
}

export type MeterDataResponse = {
  collection: MeterData[];
  has_next_page: boolean;
}

export type MeterData = {
  component: ValidMeterComponent;
  data: Array<{
    datetime: string;
    flags: string[];
    value: number;
  }>;
  meter: ValidMeter;
  stage: ValidMeterStage;
  uid: string;
  variant: string;
}

export class MdslApi extends AxiosApiClient {


  constructor(getTokenOrAuth0Client: (() => Promise<string>) | Auth0Client, authClient: Auth0Client | undefined) {
    super(URLS.mdslv4, getTokenOrAuth0Client, authClient);
    logger.trace("MDSL API initialized");
  }

  private async fetchPagedData<T extends { data?: any[] }>(
    url: string,
    params: Record<string, string>,
    transformBlock: (block: any) => T
  ): Promise<{ collection: T[] } | null> {
    let currentPage = 1;
    let haveMore = true;
    const readings = new Map<string, T>();

    try {
      while (haveMore) {
        params.page = currentPage.toString();
        const fullUrl = urlWithParams(url, params);

        const response = await this.client.get<PagedResponse<any>>(fullUrl);
        
        if (response.status === 200 && response.data) {
          const resJson = response.data;
          if (resJson.data && resJson.error === null) {
            for (const blk of resJson.data.collection) {
              const blk_id = `${blk.uid}-${blk.component}-${blk.stage}`;
              if (readings.has(blk_id)) {
                const prevReading = readings.get(blk_id)!;
                if (prevReading.data && Array.isArray(prevReading.data)) {
                  prevReading.data.push(...blk.data);
                }
              } else {
                readings.set(blk_id, transformBlock(blk));
              }
            }
            if (resJson.data.has_next_page) {
              currentPage += 1;
            } else {
              haveMore = false;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      return { collection: Array.from(readings.values()) };
    } catch (err) {
      logger.error("fetchPagedData", err);
      return null;
    }
  }

  async getNetwork(network_uid: string): Promise<Network | null> {
    logger.trace("getNetwork", { network_uid });
    return this.client.get<ApiResponse<Network>>(`/networks/${network_uid}`).then(response => response.data.data).catch(err => {
      logger.error("getNetwork", err);
      return err.error || "Error fetching network"; 
    });
  }

  async getSubstationsMeterData({
    network_uid,
    resource_uids = [],
    stage = "clean",
    meter = "primary",
    components = [],
    flags = [],
    range = {},
    ts_end,
    ts_start,
    page_size = 0,
  }: GetSubstationsMeterDataParams): Promise<MeterDataResponse | null> {
    logger.trace("getSubstationsMeterData", { network_uid, resource_uids });
    
    const params: Record<string, string> = {
      "component[in]": components.join(","),
      "stage[in]": stage,
      "meter[in]": meter,
      page_size: page_size.toString(),
      "datetime[ge]": ts_start.toISO() || "",
      "datetime[lt]": ts_end.toISO() || "",
    };

    // Add range parameters
    for (const [key, value] of Object.entries(range)) {
      params[key] = value;
    }

    // Add resource UIDs if provided
    if (resource_uids.length > 0) {
      params["uid[in]"] = resource_uids.join(",");
    }

    // Add flags if provided
    if (flags.length > 0) {
      params["flag[in]"] = flags.join(",");
    }

    return this.fetchPagedData(
      `/networks/${network_uid}/substations/meter-data`,
      params,
      (block) => block
    ) as Promise<MeterDataResponse | null>;
  }

  async getSubstationMeterData({
    network_uid,
    substation_uid,
    stage = "clean",
    meter = "primary",
    components = [],
    flags = [],
    range = {},
    ts_start,
    ts_end,
    page_size = 0,
  }: GetSingleSubstationMeterDataParams): Promise<MeterDataResponse | null> {
    const params: Record<string, string> = {
      "uid[in]": substation_uid,
      "component[in]": components.join(","),
      "stage[in]": stage,
      "meter[in]": meter,
      page_size: page_size.toString(),
      "datetime[ge]": ts_start.toISO() || "",
      "datetime[lt]": ts_end.toISO() || "",
    };

    // Add range parameters
    for (const [key, value] of Object.entries(range)) {
      params[key] = value;
    }

    // Add flags if provided
    if (flags.length > 0) {
      params["flag[in]"] = flags.join(",");
    }

    return this.fetchPagedData(
      `/networks/${network_uid}/substations/meter-data`,
      params,
      (block) => block
    ) as Promise<MeterDataResponse | null>;
  }
}
