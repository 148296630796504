/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { QueryClient, queryOptions } from "@tanstack/react-query";

import adminApi from "./MDSL/admin.api";
import networkApi, {
  GetInfoBlocksParams,
  GetSubstationMeterDataParams,
  GetWeatherDataParams,
} from "./MDSL/network.api";
import userApi from "./MDSL/user.api";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30,
    },
  },
});



export const getWeatherData = (params: GetWeatherDataParams) =>
  queryOptions({
    queryKey: ["weather", params],
    queryFn: () => networkApi.getWeatherData(params),
  });

export const getMeterData = (params: GetSubstationMeterDataParams) =>
  queryOptions({
    queryKey: ["meter", params],
    queryFn: () => networkApi.getSubstationMeterData(params),
  });

const getNetworksQueryOptions = () =>
  queryOptions({
    queryKey: ["networks"],
    queryFn: () => networkApi.getNetworks(),
    retry: 2,
  });

const getSingleNetworkQueryOptions = (networkId: string | undefined) =>
  queryOptions({
    queryKey: ["networks", networkId],
    queryFn: () => networkApi.getNetworkById(networkId!),
    enabled: !!networkId,
  });

const getAvailableFrontendFeatures = () =>
  queryOptions({
    queryKey: ["frontendFeatures"],
    queryFn: () =>
      networkApi
        .getFrontendFeatures()
        .then((res) => res.sort((a, b) => a.feature!.localeCompare(b.feature!))),
  });

const getTariffPlansQueryOptions = () =>
  queryOptions({
    queryKey: ["tariffPlans"],
    queryFn: () => adminApi.getTariffPlans(),
  });

const getSingleTariffPlanQueryOptions = (tariffPlanId: string | undefined) =>
  queryOptions({
    queryKey: ["tariffPlans", tariffPlanId],
    queryFn: () => adminApi.getTariffPlanById(tariffPlanId!),
    enabled: !!tariffPlanId,
  });

const getNetworkTariffPlansQueryOptions = (networkId: string | undefined) =>
  queryOptions({
    queryKey: ["tariffPlans", "networks", networkId],
    queryFn: () => adminApi.getNetworkTariffPlans(networkId),
    enabled: !!networkId,
  });

const getInfoBlockQueryOptions = (params: GetInfoBlocksParams) =>
  queryOptions({
    queryKey: ["infoblock", params],
    queryFn: () => networkApi.getInfoBlocks(params),
  });

export const getNetworkResources = (network: string) =>
  queryOptions({
    queryKey: ["networkResources", network],
    queryFn: () => networkApi.getNetworkResourcesV4(network),
  });

export const getClusterSubstationsOptions = (clusterId: string) =>
  queryOptions({
    queryKey: ["clusterSubstations", clusterId],
    queryFn: () => networkApi.getClusterSubstations(clusterId),
  });

const getUserProfileQueryOptions = (userId: string | undefined) =>
  queryOptions({
    queryKey: ["userProfile", userId],
    queryFn: () => userApi.getUserProfile(),
    enabled: !!userId,
  });

const getGroupsQueryOptions = () =>
  queryOptions({
    queryKey: ["groups"],
    queryFn: () => userApi.getUserGroups(),
  });

const getPoliciesQueryOptions = () =>
  queryOptions({
    queryKey: ["policies"],
    queryFn: () => userApi.getPolicies(),
  });

const getPolicyByIdQueryOptions = (policyId: string | undefined) =>
  queryOptions({
    queryKey: ["policy", policyId],
    queryFn: () => userApi.getPolicyById(policyId!),
    enabled: !!policyId,
  });

const getGroupByIdQueryOptions = (groupId: string | undefined) =>
  queryOptions({
    queryKey: ["group", groupId],
    queryFn: () => userApi.getGroupById(groupId!),
    enabled: !!groupId,
  });

const addNetworkToPolicyByIdQueryOptions = (
  policyId: string | undefined,
  networkId: string | undefined
) =>
  queryOptions({
    queryKey: ["policy", policyId],
    queryFn: () => userApi.addNetworkToPolicyById(policyId!, networkId!),
    enabled: !!policyId && !!networkId,
  });

export {
  userApi,
  getInfoBlockQueryOptions,
  getNetworksQueryOptions,
  getSingleNetworkQueryOptions,
  getAvailableFrontendFeatures,
  getTariffPlansQueryOptions,
  getSingleTariffPlanQueryOptions,
  getNetworkTariffPlansQueryOptions,
  getUserProfileQueryOptions,
  getGroupsQueryOptions,
  getPoliciesQueryOptions,
  getPolicyByIdQueryOptions,
  addNetworkToPolicyByIdQueryOptions,
  getGroupByIdQueryOptions,
};
