import { Auth0Client } from "@auth0/auth0-spa-js";

import { tokenManager } from "../../auth/tokenManager";
import { APIError } from "../../errors/apiError";
import { AxiosInstance } from "axios";
import axios from "axios";

/**
 * Base abstract API client class that provides common functionality for all API clients.
 *
 * This class standardizes:
 * - Authentication
 * - Error handling
 * - Request execution
 * - Response parsing
 */
export abstract class BaseApiClient {
  /** Default timeout for API requests in milliseconds */
  protected static readonly API_TIMEOUT = 30000; // 30 seconds

  /** Base URL for API requests */
  protected readonly baseUrl: string;

  /** Auth0 client for authentication */
  protected readonly auth0Client?: Auth0Client;

  /** Function to get authentication token */
  protected readonly getTokenFn: () => Promise<string>;

  /**
   * Creates a new instance of the BaseApiClient
   *
   * @param baseUrl - Base URL for API requests
   * @param getTokenOrAuth0Client - Either a function to get a token or an Auth0Client instance
   * @param auth0Client - Optional Auth0Client instance if getTokenOrAuth0Client is a function
   */
  constructor(
    baseUrl: string,
    getTokenOrAuth0Client: (() => Promise<string>) | Auth0Client,
    auth0Client?: Auth0Client
  ) {
    this.baseUrl = baseUrl;

    if (typeof getTokenOrAuth0Client === "function") {
      this.getTokenFn = getTokenOrAuth0Client;
      this.auth0Client = auth0Client;
    } else {
      this.auth0Client = getTokenOrAuth0Client;
      this.getTokenFn = async () => {
        // Initialize tokenManager with the Auth0 client if not already
        tokenManager.initialize(this.auth0Client!);
        return tokenManager.getToken();
      };
    }
  }

  /**
   * Get authentication headers for API requests
   *
   * @returns Promise that resolves to headers object with authentication
   */
  protected async getAuthHeaders(): Promise<Record<string, string>> {
    try {
      const token = await this.getTokenFn();
      return {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
    } catch (error) {
      throw new APIError("Failed to get authentication token", 401, "AUTH_ERROR");
    }
  }

  /**
   * Execute a GET request
   *
   * @param path - API endpoint path
   * @param params - Optional query parameters
   * @returns Promise that resolves to the response data
   */
  protected async get<T>(path: string, params?: Record<string, any>): Promise<T> {
    const url = this.buildUrl(path, params);
    return this.executeRequest<T>(url, { method: "GET" });
  }

  /**
   * Execute a POST request
   *
   * @param path - API endpoint path
   * @param data - Optional request body
   * @param params - Optional query parameters
   * @returns Promise that resolves to the response data
   */
  protected async post<T>(path: string, data?: any, params?: Record<string, any>): Promise<T> {
    const url = this.buildUrl(path, params);
    return this.executeRequest<T>(url, {
      method: "POST",
      body: data ? JSON.stringify(data) : undefined,
    });
  }

  /**
   * Execute a PUT request
   *
   * @param path - API endpoint path
   * @param data - Optional request body
   * @param params - Optional query parameters
   * @returns Promise that resolves to the response data
   */
  protected async put<T>(path: string, data?: any, params?: Record<string, any>): Promise<T> {
    const url = this.buildUrl(path, params);
    return this.executeRequest<T>(url, {
      method: "PUT",
      body: data ? JSON.stringify(data) : undefined,
    });
  }

  /**
   * Execute a PATCH request
   *
   * @param path - API endpoint path
   * @param data - Optional request body
   * @param params - Optional query parameters
   * @returns Promise that resolves to the response data
   */
  protected async patch<T>(path: string, data?: any, params?: Record<string, any>): Promise<T> {
    const url = this.buildUrl(path, params);
    return this.executeRequest<T>(url, {
      method: "PATCH",
      body: data ? JSON.stringify(data) : undefined,
    });
  }

  /**
   * Execute a DELETE request
   *
   * @param path - API endpoint path
   * @param params - Optional query parameters
   * @returns Promise that resolves to the response data
   */
  protected async delete<T>(path: string, params?: Record<string, any>): Promise<T> {
    const url = this.buildUrl(path, params);
    return this.executeRequest<T>(url, { method: "DELETE" });
  }

  /**
   * Build a URL with query parameters
   *
   * @param path - API endpoint path
   * @param params - Optional query parameters
   * @returns Full URL with query parameters
   */
  protected buildUrl(path: string, params?: Record<string, any>): string {
    const url = new URL(path.startsWith("/") ? `${this.baseUrl}${path}` : path);

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          if (Array.isArray(value)) {
            url.searchParams.append(key, value.join(","));
          } else if (typeof value === "object") {
            url.searchParams.append(key, JSON.stringify(value));
          } else {
            url.searchParams.append(key, String(value));
          }
        }
      });
    }

    return url.toString();
  }

  /**
   * Execute an HTTP request
   *
   * @param input - Request URL or Request object
   * @param init - Request initialization options
   * @returns Promise that resolves to the response data
   */
  protected abstract executeRequest<T>(input: RequestInfo, init?: RequestInit): Promise<T>;
}

/**
 * API client implementation that uses the Fetch API for HTTP requests
 */
export class FetchApiClient extends BaseApiClient {
  /**
   * Execute an HTTP request using the Fetch API
   *
   * @param input - Request URL or Request object
   * @param init - Request initialization options
   * @returns Promise that resolves to the response data
   */
  protected async executeRequest<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), BaseApiClient.API_TIMEOUT);

    try {
      const headers = await this.getAuthHeaders();
      const requestInit: RequestInit = {
        ...init,
        headers: {
          ...headers,
          ...(init?.headers || {}),
        },
        signal: controller.signal,
      };

      console.debug(`API Request: ${typeof input === "string" ? input : "Request object"}`, {
        method: init?.method,
        url: typeof input === "string" ? input : "Request object",
      });

      const response = await fetch(input, requestInit);

      console.debug(`API Response: ${response.status} ${response.statusText}`, {
        method: init?.method,
        url: typeof input === "string" ? input : "Request object",
      });

      // Handle common error responses
      if (!response.ok) {
        await this.handleErrorResponse(response);
      }

      // Handle no-content responses
      if (response.status === 204) {
        return {} as T;
      }

      try {
        return await response.json();
      } catch (error) {
        throw new APIError(
          `Invalid response format: ${error instanceof Error ? error.message : "Could not parse JSON"}`,
          500,
          "PARSE_ERROR"
        );
      }
    } catch (error) {
      if (error instanceof APIError) {
        throw error;
      }

      const errorObj = error as Error;

      if (errorObj.name === "AbortError") {
        throw new APIError("Request timed out", 504, "TIMEOUT");
      }

      throw new APIError(
        `Network request failed: ${errorObj.message || "Unknown error"}`,
        500,
        "NETWORK_ERROR"
      );
    } finally {
      clearTimeout(timeoutId);
    }
  }

  /**
   * Handle error responses from the API
   *
   * @param response - Fetch API Response object
   */
  private async handleErrorResponse(response: Response): Promise<never> {
    let errorData: any = {};

    try {
      errorData = await response.json();
    } catch {
      // If we can't parse the error response as JSON, use an empty object
    }

    const errorMessage = errorData?.error || errorData?.message || response.statusText;
    const errorCode = errorData?.code || `HTTP_${response.status}`;

    // Handle specific error cases
    if (response.status === 401) {
      if (this.auth0Client) {
        await this.auth0Client.logout();
      }
      throw new APIError("Session expired - please login again", 401, "SESSION_EXPIRED");
    }

    if (response.status === 429) {
      const retryAfter = response.headers.get("Retry-After") || "1";
      throw new APIError(
        `Rate limit exceeded. Retry after ${retryAfter} seconds.`,
        429,
        "RATE_LIMIT_EXCEEDED",
        { retryAfter }
      );
    }

    throw new APIError(errorMessage, response.status, errorCode, errorData);
  }
}

/**
 * API client implementation that uses Axios for HTTP requests
 */
export class AxiosApiClient extends BaseApiClient {
  // @ts-ignore
  static _instance: this;

  client: AxiosInstance;

  constructor(baseUrl: string, getTokenOrAuth0Client: (() => Promise<string>) | Auth0Client, auth0Client?: Auth0Client) {
    super(baseUrl, getTokenOrAuth0Client, auth0Client);

    if ((this.constructor as any)._instance) {
      console.error(`${this.constructor.name}`, "Already instantiated");
      throw new Error(`${this.constructor.name} already has an instance!!!`);
    }
    (this.constructor as any)._instance = this;

    this.client = axios.create({
      baseURL: this.baseUrl,
      timeout: BaseApiClient.API_TIMEOUT,
    });
    this.setDefaultHeaders();
  }

  async setDefaultHeaders() {
    const authHeaders = await this.getAuthHeaders();
    this.client.defaults.headers.common = authHeaders;
  }

  /**
   * Execute an HTTP request using Axios
   *
   * @param input - Request URL or Request object
   * @param init - Request initialization options
   * @returns Promise that resolves to the response data
   */
  protected async executeRequest<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    // Import axios dynamically to avoid circular dependencies
    const axios = (await import("axios")).default;

    try {
      const url = typeof input === "string" ? input : input.url;

      const method = init?.method?.toLowerCase() || "get";
      const data = init?.body
        ? typeof init.body === "string"
          ? JSON.parse(init.body)
          : init.body
        : undefined;

      console.debug(`API Request: ${url}`, {
        method,
        baseUrl: this.baseUrl,
      });

      const response = await this.client.request<T>({
        url,
        method,
        data,
        headers,
      });

      return response.data;
    } catch (error: any) {
      throw new APIError(error.message, error.status, error.code, error.response?.data);
    }
  }
}
