import { APIError } from "./apiError";

/**
 * Custom error class for token-related errors like expiration
 * @class TokenError
 * @extends APIError
 */
export class TokenError extends APIError {
  /**
   * Creates an instance of TokenError.
   * @param {string} message - Error message
   * @param {string} code - Error code
   * @param {any} [details] - Optional error details
   */
  constructor(message: string, code: string, details?: any) {
    super(message, 401, code, details);
    this.name = "TokenError";
  }

  /**
   * Creates a session expired error
   * @param {string} [message="Session expired, please login again"] - Error message
   * @returns {TokenError} Session expired error instance
   */
  static readonly SESSION_EXPIRED = (message = "Session expired, please login again") =>
    new TokenError(message, "SESSION_EXPIRED");

  /**
   * Creates a token refresh error
   * @param {string} [message="Failed to refresh token"] - Error message
   * @returns {TokenError} Token refresh error instance
   */
  static readonly REFRESH_FAILED = (message = "Failed to refresh token") =>
    new TokenError(message, "REFRESH_FAILED");
}
