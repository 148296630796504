import { Auth0Client } from "@auth0/auth0-spa-js";

import { TokenError, TokenErrorData } from "../errors/tokenError";

export class TokenManager {
  private static instance: TokenManager;
  private auth0Client?: Auth0Client;
  private getTokenFn?: () => Promise<string>;
  private tokenCache?: {
    token: string;
    expiresAt: number;
  };
  private refreshPromise?: Promise<string>;

  private constructor() {}

  static getInstance(): TokenManager {
    if (!TokenManager.instance) {
      TokenManager.instance = new TokenManager();
    }
    return TokenManager.instance;
  }

  initialize(auth0ClientOrGetToken: Auth0Client | (() => Promise<string>)) {
    if (typeof auth0ClientOrGetToken === "function") {
      this.getTokenFn = auth0ClientOrGetToken;
    } else {
      this.auth0Client = auth0ClientOrGetToken;
    }
  }

  private isTokenExpired(): boolean {
    if (!this.tokenCache) return true;
    // Add 60 second buffer before expiration
    return Date.now() >= this.tokenCache.expiresAt - 60000;
  }

  async getToken(): Promise<string> {
    try {
      // If there's an ongoing refresh, wait for it
      if (this.refreshPromise) {
        return this.refreshPromise;
      }

      // Check if we have a valid cached token
      if (this.tokenCache && !this.isTokenExpired()) {
        return this.tokenCache.token;
      }

      // Get a new token
      this.refreshPromise = this.refreshToken();
      const token = await this.refreshPromise;
      this.refreshPromise = undefined;
      return token;
    } catch (error) {
      const tokenError = error as TokenErrorData;
      this.refreshPromise = undefined;
      throw new TokenError(
        tokenError.message || "Failed to get token",
        tokenError.code || "TOKEN_ERROR"
      );
    }
  }

  private async refreshToken(): Promise<string> {
    // First check if we have a getTokenFn (direct function to get token)
    if (this.getTokenFn) {
      try {
        const token = await this.getTokenFn();
        // We don't have expiration info, so set a reasonable default (10 minutes)
        this.tokenCache = {
          token,
          expiresAt: Date.now() + 10 * 60 * 1000,
        };
        return token;
      } catch (error) {
        this.tokenCache = undefined;
        const err = error as unknown;
        const errorObj = err as Record<string, string>;
        throw new TokenError(
          errorObj.message || "Failed to get token from function",
          errorObj.error || "TOKEN_FUNCTION_ERROR"
        );
      }
    }

    // Otherwise try using Auth0Client
    if (!this.auth0Client) {
      throw new TokenError("TokenManager not initialized properly", "NOT_INITIALIZED");
    }

    try {
      const response = await this.auth0Client.getTokenSilently({
        cacheMode: "off",
        detailedResponse: true,
      });

      if (!response.access_token) {
        throw new TokenError("No token received", "NO_TOKEN");
      }

      // Cache the token with expiration
      this.tokenCache = {
        token: response.access_token,
        expiresAt: Date.now() + response.expires_in * 1000,
      };

      return response.access_token;
    } catch (error) {
      // Clear cache on error
      this.tokenCache = undefined;

      const tokenError = error as TokenErrorData;
      if (tokenError.error === "login_required") {
        await this.auth0Client.logout();
        throw new TokenError("Session expired", "SESSION_EXPIRED");
      }

      throw new TokenError(
        tokenError.message || "Failed to refresh token",
        tokenError.error || "REFRESH_ERROR"
      );
    }
  }

  async getAuthHeaders(): Promise<Record<string, string>> {
    const token = await this.getToken();
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }

  clearCache(): void {
    this.tokenCache = undefined;
    this.refreshPromise = undefined;
  }
}

export const tokenManager = TokenManager.getInstance();
