import { Suspense, useState } from "react";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react";
import { CssBaseline } from "@mui/material";
import { setUseWhatChange } from "@simbathesailor/use-what-changed";

import { log } from "@core/logger";

import { RouteContent } from "./components/app/RouteContent";
import { ServiceWorker } from "./components/app/ServiceWorker";
import InteractionDialog from "./stores/Interaction/InteractionDialog.component";

import "./app.css";
import "react-toastify/dist/ReactToastify.css";
import { ApiContext } from "@stores/apis/Api.provider";
import { MdslApi } from "@core/apis/MDSL/mdsl.api";
import { useInject, useReaction } from "@hooks";

export const App = observer(function AppRoot() {
  setUseWhatChange(import.meta.env?.DEV || false);
  const { session } = useInject("session");
  const [mdslApi, setMDSLApi] = useState<MdslApi | null>(null);

  useReaction(
    () => session.isAuthenticated,
    () => {
      setMDSLApi(
        new MdslApi(
          session.getToken,
          session.client,
        ));
    },
    { fireImmediately: true }
  );


  
  return (
    <>
      <ApiContext.Provider value={{ mdslApi: mdslApi }}>
        <ServiceWorker />
        <CssBaseline />
        <Suspense fallback="loading">
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            limit={2}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <InteractionDialog />
          <RouteContent />
        </Suspense>
      </ApiContext.Provider>
    </>
  );
});

log(
  [
    `PLATFORM APP:${import.meta.env.MODE}`,
    `[ v${process.env?.APP_VERSION} ]`,
    `${process.env?.BUILD_DATE}`,
  ].join(" ")
);
log("Feature Flags: ", import.meta.env.REACT_APP_FEATURES);
