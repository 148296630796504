import { MdslApi } from "@core/apis/MDSL/mdsl.api";
import { createContext, useContext } from "react";


export const ApiContext = createContext<{
  mdslApi: MdslApi | null;
} | null>(null);

export const MdslApiProvider = ApiContext.Provider;

export function useMdslApi(): MdslApi {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useMdslApi must be used within a UtfApiProvider");
  }
  if (!context.mdslApi) {
    throw new Error("MDSL API is not available");
  }
  return context.mdslApi
} 
